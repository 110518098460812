<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <CRow>
      <CCol>
        <ListDriver
          v-bind:tableItems="listDrivers"
          v-bind:totalPages="totalPages"
          v-bind:countryCodes="countryCodes"
          @driver-edit="showcardDriverEdit"
          @driver-new="showCardDriverNew"
          @driver-delete="showcardDriverDelete"
          @driver-filter="loadDataFilter"
          @driver-reset-password="resetPassword"
          @disable-driver="disableDriver"
        />
      </CCol>
    </CRow>

    <transition name="slide-fade">
      <CardDriver
        v-if="showCardDriver"
        v-bind:driver="driverSelected"
        v-bind:title="cardTitle"
        v-bind:notification="notification"
        v-bind:notificationType="notificationType"
        v-bind:btnEventText="btnEventText"
        v-bind:btnEventType="btnEventType"
        v-bind:inputsDisabled="inputsDisabled"
        v-bind:editFieldsDisabled="editFieldsDisabled"
        v-bind:action="action"
        v-bind:usersRCS="usersRCS"
        v-bind:companyCodes="companyCodes"
        v-bind:carriers="carriers"
        v-bind:showCollapseTop="showCollapseTop"
        v-bind:showCollapseBottom="showCollapseBottom"
        v-bind:showCollapseCarriers="showCollapseCarriers"
        v-bind:countryCodes="countryCodes"
        v-bind:systemsByCountry="systemsByCountry"
        @search-user-rcs="loadUsersRCS"
        @load-company-codes="loadCompanyCodes"
        @search-carrier="loadCarriers"
        @card-driver-error="showToastError"
        @assing-user-rcs="assingUserRCS"
        @assing-carrier="assingCarrier"
        @approved-user="approvedUser"
        @save-products="saveProducts"
        @save-tpps="saveTpps"
        @delete-user="deleteUser"
        @remove-carrier="removeCarrier"
        @close-card-driver="closeCardDriver"
        @card-driver-update="updateDriver"
      />
    </transition>
    <transition name="slide-fade">
      <CardDriverNew
        v-if="showCardNewDriver"
        v-bind:title="cardTitle"
        v-bind:notification="notification"
        v-bind:notificationType="notificationType"
        v-bind:inputsDisabled="inputsDisabled"
        v-bind:usersRCS="usersRCS"
        v-bind:companyCodes="companyCodes"
        v-bind:carriers="carriers"
        v-bind:showCollapseTop="showCollapseTop"
        v-bind:showCollapseBottom="showCollapseBottom"
        v-bind:showCollapseCarriers="showCollapseCarriers"
        v-bind:countryCodes="countryCodes"
        v-bind:systemsByCountry="systemsByCountry"
        @search-user-rcs="loadUsersRCS"
        @load-company-codes="loadCompanyCodes"
        @search-carrier="loadCarriers"
        @card-driver-error="showToastError"
        @approved-user="approvedUser"
        @create-driver="createDriver"
        @delete-user="deleteUser"
        @close-card-driver="closeCardDriverNew"
      />
      <CardDriverResetPassword
        v-if="showCardResetPassword"
        v-bind:driver="driverSelected"
        v-bind:title="cardTitle"
        v-bind:notification="notification"
        v-bind:notificationType="notificationType"
        v-bind:btnEventText="btnEventText"
        v-bind:btnEventType="btnEventType"
        v-bind:inputsDisabled="inputsDisabled"
        v-bind:usersRCS="usersRCS"
        v-bind:showCollapseTop="showCollapseTop"
        v-bind:showCollapseBottom="showCollapseBottom"
        v-bind:countryCodes="countryCodes"
        @reset-password-user="resetPasswordUser"
        @close-card-driver="closeCardDriverReset"
      />
      
    </transition>

    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import ListDriver from "../components/ListDriver.vue";
import CardDriver from "../components/CardDriver";
import CardDriverNew from "../components/CardDriverNew";
import CardDriverResetPassword from "../components/CardDriverResetPassword";

export default {
  name: "Dashboard",
  components: {
    ListDriver,
    CardDriver,
    CardDriverNew,
    CardDriverResetPassword
  },
  data() {
    return {
      loading: true,

      listDrivers: [],
      totalPages: 0,
      totalItems: 0,
      countryCodes: [],
      companyCodes: [],
      systemsByCountry: [],
      prevFilter: {},

      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",

      // props cardDriver
      showCardDriver: false,
      showCardNewDriver: false,
      showCardResetPassword: false,
      driverSelected: null,
      cardTitle: null,
      notification: null,
      notificationType: null,
      btnEventText: null,
      btnEventType: null,
      inputsDisabled: null,
      editFieldsDisabled: null,
      showCollapseTop: null,
      showCollapseCarriers: false,
      showCollapseBottom: null,
      action: null,

      usersRCS: null,
      carriers: []
    };
  },
  async created() {
   // await this.loadData();
    await this.loadCountryData();
  },
  methods: {
    async loadData(filter) {
      const self = this;
       self.loading = true;
       if(!filter){
         filter = self.prevFilter
       }else{
         self.prevFilter = filter
       }
      // self.listDrivers = [];
      

      try {
        const res = await self.$http.post(`apiConsole/console/getDriversPaginated/${filter.currentPage}/${filter.perPage}`,
          {
            country: filter.country,
            tableFilter: filter.tableFilter,
            driverStatus: filter.driverStatus,
            tpps: filter.tpps
          });
          let approvedTxt = self.$t('approved');
          let pendingTxt = self.$t('pending');
          self.listDrivers = res.data.drivers.map((item, id) => {
            item.approvedKey = item.approved;
            item.approved = item.approved ? approvedTxt : pendingTxt;
            return { ...item, id };
        });

        self.listDrivers = self.listDrivers.sort((a, b) =>
          a.approved > b.approved ? -1 : b.approved > a.approved ? 1 : 0
        );
        self.totalItems = res.data.totalItems;
        self.totalPages = Math.ceil(self.totalItems / filter.perPage);
        self.listDrivers = self.listDrivers.map(x=>{return {...x,region:x.region ? x.region.toString():"",countryCode:x.countryCode ? x.countryCode.toString():""}})
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
      self.loading = false;
    },
    async loadCountryData() {
      const self = this;
      self.countryCodes = [];

      var rol = JSON.parse(localStorage.getItem("userRol"));
      if (rol == "superadmin") self.countryCodes = [this.$t('all_countries')];
      // if (rol == "logisticAdmin") {
      //   let country = JSON.parse(localStorage.getItem("userCountry"))
      //   let countryCarrier = ""
      //   if (country) {
      //     await self.loadCompanyCodes(country)
      //   }
      //   console.log(companyCodes, 'from dashboard', country)
      //   const res = await self.$http.post("apiConsole/console/getCarriers", {
      //     country: "",
      //     companyCode: "",
      //     carrier: ""
      //   })
      // }
      /*if (rol == "logisticAdmin") {
        const res = await self.$http.get("apiConsole/console/getLogistiCarriers")
        localStorage.setItem("logistiCarrier", JSON.stringify(res.data))
      }*/
      try {
        const res = await self.$http.get("apiConsole/console/getCountries");
        var countries = res.data.countries;
        countries.map((e) => {
          self.countryCodes.push(e.code);
          self.systemsByCountry.push({
            code: e.code,
            systems: e.systems
          });
        });
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },
    async loadUsersRCS(user) {
      const self = this;

      try {
        const res = await self.$http.post("apiConsole/console/getRCSDrivers", {
          country: user.sapCountry,
          name: user.name,
          driverId: "",
          license: user.license,
        });

        if (res.data.drivers.length > 100) {
          this.showToastInfo(
            self.$t("too_many_users")
          );
        } else self.usersRCS = res.data.drivers;
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },
    async loadCompanyCodes(country) {
      const self = this;
      self.companyCodes = [];
      try {
        const res = await self.$http.get(`apiConsole/console/companyCodes/${country}`);
        self.companyCodes = res.data.map(cc => ({ 
          code: cc.companyCode_CD,
          _key: cc.companyCode_CD,
          value: cc.companyCode_Name 
        }));
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },
    async loadCarriers(data) {
      const self = this;
      try {
        const res = await self.$http.post(`apiConsole/console/getCarriers`, {
          country: data.country,
          companyCode: data.carrier.companyCode,
          carrier: data.carrier
        });
        if (res.data.length > 100) {
          this.showToastInfo(
            "The search exceeds the maximum number of carriers, change the filtering."
          );
        } else if(!res.data.length) {
          this.showToastInfo(
            self.$t('carrier_not_found')
          );
        } else {
          self.carriers = res.data;
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },

    async loadDataFilter(filter) {
      await this.loadData(filter);
    },

    async assingUserRCS(data) {
      const self = this;

      try {
        const res = await self.$http.put("apiConsole/console/updateDriver", {
          sapId: data.userRCS.driverNumberId,
          license: data.userRCS.licenseNumber,
          username: data.username,
          RCSUserName: data.userRCS.name,
          mobiUserNo: data.userRCS.mobiUserNo,
          country: data.country || data.userRCS.country
        });
        let updatedDriver = res.data.updatedDriver;
        if(data.preventReload){
          return;
        }
        self.showToastInfo(self.$t("driver_updated_succesfully"));
        self.closeCardDriver(false);

        await self.loadData();

        let driverSelected = updatedDriver;
        await self.reloadCard(driverSelected, self.$t("approved"));
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async updateDriver(data) {
      const self = this;
      try {
        const res = await self.$http.put(`apiConsole/console/updateDriver`, {
          updateDriver: true,
          firstName: data.firstName,
          lastName: data.lastName,
          license: data.license,
          phoneNumber: data.phoneNumber,
          username: data.username
        })

        self.showToastInfo(self.$t('driver_update_successfully'))
        self.closeCardDriver(false);
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async assingCarrier(data) {
      const self = this;
      try {
        if(!data.username || !data.carrier || !data.carrier.carrier || !data.carrier.name) {
          console.log(data);
          self.showToastError(self.$t("introduce_neccesary_fields_carrier"));
          return;
        }
        const res = await self.$http.put("apiConsole/console/updateDriverCarrier", {
          username: data.username,
          carrier: data.carrier.name,
          carrierId: data.carrier.carrier
        });
        let updatedDriver = res.data.updatedDriver;
        self.showToastInfo(self.$t("driver_updated_succesfully"));
        self.closeCardDriver(false);

        await self.loadData();
        self.showCardDriver = true;
        self.driverSelected = updatedDriver;
        self.inputsDisabled = true;
        self.usersRCS = [];
        self.btnEventText = self.$t("approved");
        self.btnEventType = "success";
        self.cardTitle = self.$t("edit_driver_header");
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async disableDriver(username) {
      let self = this
      try {
        const res = await self.$http.put("apiConsole/console/disableDriver", {
          username: username
        })
        if (res.data.driverDisabled) {
          self.showToastInfo(`${self.$t("disabled_driver")}  ${username}`)
          await self.loadData();
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async removeCarrier(driver) {
      const self = this;
      try {
        const res = await self.$http.put("apiConsole/console/updateDriverCarrier", {
          username: driver.username,
          carrier: '',
          carrierId: ''
        });
        let updatedDriver = res.data.updatedDriver;
        self.showToastInfo(self.$t("carrier_removed_succesfully"));
        self.closeCardDriver(false);

        await self.loadData();
        self.showCardDriver = true;
        self.driverSelected = updatedDriver;
        self.inputsDisabled = true;
        self.usersRCS = [];
        self.btnEventText = self.$t("approved");
        self.btnEventType = "success";
        self.cardTitle = self.$t("edit_driver_header");
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async saveProducts(user) {
      const self = this;
      try {
        const res = await self.$http.put("apiConsole/console/updateDriver", {
          products: user.products,
          username: user.username,
          tpps: user.tpps
        });
        let updatedDriver = res.data.updatedDriver;

        self.showToastInfo(self.$t("products_updated_successfully"));
        self.closeCardDriver(false);

        await self.loadData();
        let driverSelected = updatedDriver;
        await self.reloadCard(driverSelected);
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async saveTpps(user) {
      const self = this;
      try {
        const res = await self.$http.put("apiConsole/console/updateDriverTpps", {
          tpps: user.tpps,
          username: user.username,
        });
        let updatedDriver = res.data.updatedDriver;

        self.showToastInfo(self.$t("tpps_updated_successfully"));
        self.closeCardDriver(false);

        await self.loadData();
        let driverSelected = updatedDriver;
        await self.reloadCard(driverSelected);
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async reloadCard(driverSelected, text) {
      const self = this;
      const response = await self.$http.post(
        "apiConsole/console/getRCSDrivers",
        {
          country: driverSelected.countryCode,
          name: driverSelected.name,
          driverId: "",
          license: driverSelected.license,
        }
      );

      if (driverSelected.sapId == undefined) {
        self.showCollapseTop = true;
        self.showCollapseBottom = false;

        if (response.data.drivers.length > 0) {
          self.notificationType = "warning";
          self.notification =
           self.$t("rcs_found");
        } else {
          self.notificationType = "warning";
          self.notification =
          self.$t("rcs_not_registered");
        }
      } else {
        self.showCollapseTop = false;
        self.showCollapseBottom = true;
      }

      self.usersRCS = response.data.drivers;

      self.showCardDriver = true;
      self.driverSelected = driverSelected;
      self.inputsDisabled = true;
      self.btnEventText = text || driverSelected.approved ?  self.$t("save") : self.$t("approved");
      self.btnEventType = "success";
      self.cardTitle = self.$t("edit_driver_header");
    },
    async approvedUser(username) {
      const self = this;
      try {
        const res = await self.$http.put("apiConsole/console/approveDriver", {
          username,
        });
        if (res.data.approvedDriver) {
          await self.loadData();

          self.showToastInfo(self.$t("driver_approved_succesfully"));
          self.closeCardDriver(false);
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async createDriver(driver) {
      const self = this;
      try {
        const res = await self.$http.post("apiConsole/console/createDriver", {
          driver: driver,
        });
        await self.loadData();

        self.showToastInfo(self.$t("driver_created_succesfully"));
        self.closeCardDriverNew(false);
      } catch (err) {
        if (err.response) {
          const res = err.response;
          if(res.data.message.includes("sapId_1")) {
            self.showToastError(self.$t("driver_already_registered"));
          } else {
            self.showToastError(res.data.message);
          }
        }
        console.error(err);
      }
    },
    async deleteUser(username) {
      const self = this;
      try {
        console.log(username);
        const res = await self.$http.put("apiConsole/console/deleteDriver", {
          username: username,
        });
        if (res.data.deletedDriver) {
          await self.loadData();

          self.showToastInfo(self.$t("driver_deleted_succesfully"));
          self.closeCardDriver(false);
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async showCardDriverNew(cardDriver) {
      const self = this;
      self.showCollapseTop = true;
      self.showCollapseBottom = true;
      self.usersRCS = [];
      self.carriers = [];
      self.showCardNewDriver = cardDriver.showCardDriver;
      self.driverSelected = cardDriver.driverSelected;
      self.cardTitle = cardDriver.cardTitle;
      self.btnEventText = cardDriver.btnEventText;
      self.btnEventType = cardDriver.btnEventType;
      self.inputsDisabled = cardDriver.inputsDisabled;
      self.countryCodes = cardDriver.countryCodes;
    },
    async resetPassword(cardDriver) {
      const self = this;
      self.driverSelected = cardDriver.driverSelected;
      self.showCardResetPassword = true;
      self.showCollapseBottom = true;
      self.inputsDisabled = true;
           self.notification = cardDriver.notification;
      self.notificationType = cardDriver.notificationType;
    },
    async resetPasswordUser(username) {
      const self = this;
      try {
        console.log(username);
        const res = await self.$http.put(
          "apiConsole/console/resetDriverPassword",
          {
            username: username,
          }
        );

        await self.loadData();

        self.showToastInfo(self.$t("new_password_sent"));
        self.closeCardDriverReset(false);
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },

    async showcardDriverEdit(cardDriver) {
      const self = this;
      const driverSelected = cardDriver.driverSelected;
      try {
        const res = await self.$http.post("apiConsole/console/getRCSDrivers", {
          country: driverSelected.countryCode,
          name: driverSelected.name,
          driverId: "",
          license: driverSelected.license,
        });

        if (driverSelected.sapId == undefined) {
          self.showCollapseTop = true;
          self.showCollapseBottom = false;

          if (res.data.drivers.length > 0) {
            self.notificationType = "warning";
            self.notification =
              self.$t("rcs_found");
          } else {
            self.notificationType = "warning";
            self.notification =
              self.$t("rcs_not_registered");
          }
        } else {
          self.showCollapseTop = false;
          self.showCollapseBottom = true;
        }

        self.usersRCS = res.data.drivers;
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
        self.usersRCS = [];
        self.showCollapseTop = false;
        self.showCollapseBottom = true;
      }

      self.showCardDriver = cardDriver.showCardDriver;
      self.action = cardDriver.action;
      self.driverSelected = cardDriver.driverSelected;
      self.cardTitle = cardDriver.cardTitle;
      self.btnEventText = cardDriver.btnEventText;
      self.btnEventType = cardDriver.btnEventType;
      self.inputsDisabled = cardDriver.inputsDisabled;
      self.countryCodes = cardDriver.countryCodes;
      self.editFieldsDisabled = cardDriver.editFieldsDisabled;
    },
    // TODO: falta implementar
    showcardDriverDelete(cardDriver) {
      const self = this;
      self.showCardDriver = cardDriver.showCardDriver;
      self.driverSelected = cardDriver.driverSelected;
      self.cardTitle = cardDriver.cardTitle;

      self.notification = cardDriver.notification;
      self.notificationType = cardDriver.notificationType;

      self.btnEventText = cardDriver.btnEventText;
      self.btnEventType = cardDriver.btnEventType;
      self.action = cardDriver.action;
      self.carriers = null;

      self.inputsDisabled = cardDriver.inputsDisabled;
      self.showCollapseBottom = true;
    },

    showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
    showToastInfo(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "success";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },

    closeCardDriver(toggle) {
      // TODO: reseteamos las props del cardDriver
      this.showCardDriver = toggle;
      this.driverSelected = null;
      this.cardTitle = null;
      this.notification = null;
      this.notificationType = null;
      this.btnEventText = null;
      this.btnEventType = null;
      this.inputsDisabled = null;
      this.carriers = [];
      this.usersRCS = null;
      this.action = null;
    },
    closeCardDriverNew(toggle) {
      // TODO: reseteamos las props del cardDriver
      this.showCardNewDriver = toggle;
      this.driverSelected = null;
      this.cardTitle = null;
      this.notification = null;
      this.notificationType = null;
      this.btnEventText = null;
      this.btnEventType = null;
      this.inputsDisabled = null;
      this.carriers = [];
      this.usersRCS = null;
    },
    closeCardDriverReset(toggle) {
      // TODO: reseteamos las props del cardDriver
      this.showCardResetPassword = toggle;
      this.driverSelected = null;
      this.cardTitle = null;
      this.notification = null;
      this.notificationType = null;
      this.btnEventText = null;
      this.btnEventType = null;
      this.inputsDisabled = null;
      this.carriers = [];
      this.usersRCS = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-toast {
  z-index: 11000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>